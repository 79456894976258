import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {deserializeObject, getObjectiveDataWithMatchedData} from "../helpers/utils";
import Select from "react-select";

interface SelectWrapperProps {
    name?: string;
    accessor?: string;

    classNamePrefix: string;
    quickFilters: Array<any>;

    isMulti?: boolean;
    menuShouldBlockScroll?: boolean;
    menuShouldScrollIntoView?: boolean;
    tabSelectsValue?: boolean;
    backspaceRemovesValue?: boolean;
    defaultValue?: any,
    isConstantData?: boolean;

    options: { label: string, value: string }[];
    placeholder: string;
    onChange?: Function;
    reset?: Function;
}

export default function SearchSelectWrapper(props: SelectWrapperProps) {

    const location: any = useLocation();

    const {
        name,
        accessor,
        classNamePrefix,
        quickFilters,
        isMulti,
        menuShouldBlockScroll,
        menuShouldScrollIntoView,
        tabSelectsValue,
        backspaceRemovesValue,
        defaultValue,
        isConstantData = false,
        options,
        placeholder,
        onChange,
    } = props;

    let [values, setValues] = useState(defaultValue);

    useEffect(() => {
        if (location.search && options) {

            const queryParamsObject = deserializeObject(location.search);

            isMulti ?
                setValues(queryParamsObject?.hasOwnProperty(accessor) ? queryParamsObject[accessor] : [defaultValue])
                :
                setValues(queryParamsObject?.hasOwnProperty(accessor) ? queryParamsObject[accessor] : defaultValue);

            console.log("location.search", values, options?.length, accessor , queryParamsObject?.hasOwnProperty(accessor) ,queryParamsObject[accessor]);

        }
    }, [location.search]);

    useEffect(() => {
        if (options) {

            const queryParamsObject = deserializeObject(location.search);

            if (queryParamsObject instanceof Object && Object.keys(queryParamsObject).length > 0) {
                Object.keys(queryParamsObject).map((cur, i) => {
                    if (cur === accessor) {
                        setValues(values);
                    }
                })
            }
        }
    }, [options]);

    useEffect(() => {

        if (location.search) {
            const queryParamsObject = deserializeObject(location.search);

            if (queryParamsObject instanceof Object && Object.keys(queryParamsObject).length > 0) {
                Object.keys(queryParamsObject).map((cur, i) => {
                    if (cur === accessor) {
                        setValues(queryParamsObject[cur]);
                    }
                })
            }
        }
    }, []);


    const handleChange = (value: any) => {
        location.state = undefined;
        setValues(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);

        if (!onChange) return;
        onChange(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);
    };


    return <Select
        name={name}
        classNamePrefix={classNamePrefix}

        isMulti={isMulti}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        tabSelectsValue={tabSelectsValue}
        backspaceRemovesValue={backspaceRemovesValue}
        blurInputOnSelect={!isMulti}
        isClearable
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        isDisabled={!options || !options.length}
        value={getObjectiveDataWithMatchedData(isMulti, options, values, setValues)}
    />;
};
