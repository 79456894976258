import React, {useEffect, useState} from 'react';
import defaultLogo from '../../images/titleLogixLogo.png';
import "./Header.css";
import "../../app/App.css";
import {useDispatch, useSelector} from "react-redux";
import SearchOrderModal from "./template/modal/SearchOrderModal";
import {
    getHumanReadableDateDifference,
    formatValueAsDate,
    getNotificationIcon,
    notificationSorting, isUserHaveAccess, useAuthorities, fetchPdf
} from "../../common/helpers/utils";
import {homeActions} from "../home/home";
import moment from "moment";
import SkeletonCell from "../../common/components/SkeletonCell";
import {useHistory} from "react-router-dom";
import {tenantActions} from "../admin/tenant/tenant";

function Header() {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [orderNumber, setOrderNumber] = useState("");
    const [username, setUsername] = useState("");
    const [tenant, setTenant] = useState("");

    const authorities: any = useAuthorities();

    const storageData = useSelector((state: any) => state.utilDataReducer);
    const [tenantLogo, setTenantLogo] = useState(defaultLogo);
    const history = useHistory(); // Get the history object

    const selector = (state: any) => state.getOrdersListByOrderNumberReducer;

    useEffect(() => {
        if (storageData?.items?.userName) {
            setUsername(storageData.items.userName);
        }
        if (storageData?.items?.tenant) {
            setTenant(storageData.items.tenant);
        }
    }, [storageData]);

    useEffect(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll("/", "&");
        if(!storageData?.loggingIn){
            dispatch(homeActions.utilsDataAction(timeZone))
        }
    }, []);

    useEffect(() => {
        const fetchTenantLogo = async () => {
            try {
                const tenant = storageData?.items?.tenant;
                const fileUrl = `/ajax/tenant-logo/${tenant}`;
                const blobUrl = await fetchPdf(fileUrl);
                setTenantLogo(blobUrl);
            } catch (error) {
                console.error('Error fetching tenant logo:', error);
                setTenantLogo(defaultLogo); // No fallback logo displayed during error
            }
        };
        if (tenant) {
            fetchTenantLogo();
        }
    }, [tenant]);

    const signOut = () => {
        localStorage.clear();
        sessionStorage.clear();

        dispatch(homeActions.resetReducerAction(["utilDataReducer"]))
        const baseUrl = `${window.location.host}`;
        if (baseUrl) {
            dispatch(tenantActions.getTenantByUrlAction(baseUrl));
        }
        history.push("/login"); // Use history.push to navigate
    }

    const handleSearchOrder = (e: any) => {
        e.preventDefault();
        if (orderNumber !== '' && orderNumber !== null && !showLoader) {
            setShow(true);
            setShowLoader(true);
        }
    }

    const markAsRead = (curNotification) => {
        if (storageData?.items && curNotification.read === false) {
            dispatch(homeActions.markAsReadNotificationAction(curNotification.id, curNotification.url));
        } else {
            if (curNotification.url != null && curNotification.url !== '') {
                window.open(curNotification.url, '_blank', 'noopener,noreferrer');
            }
        }
    }

    const markListAsUnRead = (e) => {
        e.preventDefault();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll("/", "&");
        dispatch(homeActions.markListAsUnReadNotificationAction(timeZone));
    }

    return (

        <nav className="navbar navbar-expand-lg navbar-dark tgx_navbar">
            <a className="navbar-brand" href="#"><img className={tenantLogo == defaultLogo ? "blur_image":""} width="20" height="28"
                                                      src={tenantLogo} alt="logo"/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="right_nav">
                    {isUserHaveAccess(authorities, ["ORDR-V", "ORDR-R"], true) && <div className="form-inline">
                        <div className="position-relative">
                            <input type="text" className="search_bar"
                                   onChange={(e) => setOrderNumber(e.target.value)}
                                   placeholder="Search by Order Number..."
                                   value={orderNumber}
                                   onKeyPress={(e: any) => {
                                       if (e.key === 'Enter') {
                                           handleSearchOrder(e)
                                       }
                                   }}
                                   name="search"
                                   disabled={showLoader}
                            />
                            <button className="search search_btn" type="button">

                                {orderNumber !== "" && (showLoader === false) &&
                                    <i className="fa fa-close search_data" onClick={() => {
                                        setOrderNumber("")
                                    }} aria-hidden="true"/>}

                                <i className={showLoader === false ? (orderNumber !== "" ? "fa fa-search" : "fa fa-search ml-3") : "fa-spin fa-sync fas ml-3"}
                                   aria-hidden="true"
                                   onClick={(e: any) =>
                                       handleSearchOrder(e)
                                   }
                                />

                            </button>
                        </div>
                    </div>
                    }
                    <div className="dropdown notification">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-envelope"/>
                            {storageData?.items?.notifications?.filter(n => !n.read).length > 0 &&
                                <span>{storageData?.items?.notifications.filter(n => !n.read).length}</span>
                            }
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <div className={"d-flex"}>
                                <h5 className={"mb-2 ml-3 mt-2"}>
                                    Notifications
                                </h5>
                                <span className="ml-auto  mt-2">
                                    <i className={storageData?.items?.notifications?.filter(n => !n.read).length > 0 ? "fa-solid fa-envelope-open mr-3 icon_data" : "fa-solid fa-envelope-open mr-3 disabled-cursor disabled_notification icon_data"}
                                       title="Mark all as read"
                                       style={{cursor: "pointer"}}
                                       onClick={(e) => {
                                           if (storageData?.items?.notifications?.filter(n => !n.read).length) {
                                               markListAsUnRead(e);
                                           }
                                       }}>
                                       </i>
                                </span>

                            </div>
                            <ul className={"with-max-notification"}>
                                {storageData?.items?.notifications?.length > 0 && notificationSorting(storageData?.items?.notifications, ["read", "creationDate"], ["asc", "desc"]).map((notification) => {
                                    return (
                                        <li className={notification.read === false ? 'unread_notification cursor' : 'read_notification cursor'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                markAsRead(notification);
                                            }}>
                                            <div>
                                                <i className={notification.read === true ? getNotificationIcon(notification.type) + '' : getNotificationIcon(notification.type) + ' fa_icon_read'}/>
                                            </div>
                                            <div className={"notification_content"}>
                                                <a className={notification.url != null && notification.url !== '' && "column-link active cursor"}>{notification.description}</a>
                                                <br/>
                                                <p title={formatValueAsDate(notification.creationDate)}
                                                   className={"notification_data"}>{getHumanReadableDateDifference(moment(notification.creationDate))}</p>
                                            </div>
                                        </li>
                                    )
                                })
                                }

                                {storageData?.loggingIn === true ?
                                    [...Array(12)].map((elementInArray, index) => (<li>
                                            <SkeletonCell/>
                                        </li>)
                                    )
                                    : storageData?.items?.notifications?.length === 0 &&
                                    <li>No notifications to display</li>
                                }
                            </ul>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <a className="d-inline header-bar cursor"
                               title="Edit User Settings"
                               onClick={(e) => {
                                   window.location.href = "/profile"
                               }}>
                                <i className="fa fa-cog cursor-pointer mr-2"/>
                            </a>
                            {username}
                        </li>
                        <li>
                            <a onClick={(e) => signOut()}><i className="fas fa-sign-out-alt mr-2 cursor-pointer"/></a>
                        </li>
                    </ul>

                </div>
            </div>

            {
                show &&
                <SearchOrderModal show={show} setShow={setShow} customerOrderNumber={orderNumber} selector={selector}
                                  setShowLoader={setShowLoader}/>
            }
        </nav>

    )
        ;
}

export default Header;