
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";
import {alertActions} from "../../alert/alert";
import {showErrorToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";

//export actions
export const tenantActions = {
    getAllTenantAction,
    createTenantAction,
    updateTenantAction,
    getTenantByIdAction,
    getTenantByUrlAction,
    uploadTenantLogoAction,
};

//export constants
export const tenantConstants = {
    GET_ALL_TENANT_REQUEST: 'GET_ALL_TENANT_REQUEST',
    GET_ALL_TENANT_SUCCESS: 'GET_ALL_TENANT_SUCCESS',
    GET_ALL_TENANT_FAILURE: 'GET_ALL_TENANT_FAILURE',

    CREATE_TENANT_REQUEST: 'CREATE_TENANT_REQUEST',
    CREATE_TENANT_SUCCESS: 'CREATE_TENANT_SUCCESS',
    CREATE_TENANT_FAILURE: 'CREATE_TENANT_FAILURE',

    UPDATE_TENANT_REQUEST: 'UPDATE_TENANT_REQUEST',
    UPDATE_TENANT_SUCCESS: 'UPDATE_TENANT_SUCCESS',
    UPDATE_TENANT_FAILURE: 'UPDATE_TENANT_FAILURE',

    GET_TENANT_BY_ID_REQUEST: 'GET_TENANT_BY_ID_REQUEST',
    GET_TENANT_BY_ID_SUCCESS: 'GET_TENANT_BY_ID_SUCCESS',
    GET_TENANT_BY_ID_FAILURE: 'GET_TENANT_BY_ID_FAILURE',

    GET_TENANT_BY_NAME_REQUEST: 'GET_TENANT_BY_NAME_REQUEST',
    GET_TENANT_BY_NAME_SUCCESS: 'GET_TENANT_BY_NAME_SUCCESS',
    GET_TENANT_BY_NAME_FAILURE: 'GET_TENANT_BY_NAME_FAILURE',

    GET_TENANT_BY_URL_REQUEST: 'GET_TENANT_BY_URL_REQUEST',
    GET_TENANT_BY_URL_SUCCESS: 'GET_TENANT_BY_URL_SUCCESS',
    GET_TENANT_BY_URL_FAILURE: 'GET_TENANT_BY_URL_FAILURE',

    UPLOAD_TENANT_LOGO_REQUEST: 'UPLOAD_TENANT_LOGO_REQUEST',
    UPLOAD_TENANT_LOGO_SUCCESS: 'UPLOAD_TENANT_LOGO_SUCCESS',
    UPLOAD_TENANT_LOGO_FAILURE: 'UPLOAD_TENANT_LOGO_FAILURE',
};

//export service
export const tenantService = {
    getAllTenant,
    createTenant,
    updateTenant,
    getTenantById,
    getTenantByName,
    getTenantByUrl,
    uploadTenantLogoService
};

{/************************************* tenant Actions Starts here  ******************************************/
}

function getAllTenantAction(query) {
    return dispatch => {
        dispatch(request());

        tenantService.getAllTenant(query)

            .then(
                tenants => dispatch(success(tenants)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: tenantConstants.GET_ALL_TENANT_REQUEST}
    }

    function success(tenants) {
        return {type: tenantConstants.GET_ALL_TENANT_SUCCESS, tenants}
    }

    function failure(error) {
        return {type: tenantConstants.GET_ALL_TENANT_FAILURE, error}
    }
}

function createTenantAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        tenantService.createTenant(obj)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {
                        showSuccessToast("Tenant added successfully!");
                        dispatch(success(stateCode));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(stateCode);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(tenant) {
        return {type: tenantConstants.CREATE_TENANT_REQUEST, tenant}
    }

    function success(tenant) {
        return {type: tenantConstants.CREATE_TENANT_SUCCESS, tenant}
    }

    function failure(error) {
        return {type: tenantConstants.CREATE_TENANT_FAILURE, error}
    }
}

function updateTenantAction(obj, tenantId, from, setDisable, file, icon) {
    return dispatch => {
        dispatch(request({obj}));

        tenantService.updateTenant(obj, tenantId)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {

                        if (file !== null || icon !== null) {
                            dispatch(uploadTenantLogoAction(tenantId, file, icon, setDisable, from,"Tenant updated successfully!"));
                        } else {
                            showSuccessToast("Tenant updated successfully!");
                            setDisable(false)
                            history.push(from);
                        }
                    } else {
                        responseDTOExceptionHandler(stateCode);
                        setDisable(false)
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(tenant) {
        return {type: tenantConstants.UPDATE_TENANT_REQUEST, tenant}
    }

    function success(tenant) {
        return {type: tenantConstants.UPDATE_TENANT_SUCCESS, tenant}
    }

    function failure(error) {
        return {type: tenantConstants.UPDATE_TENANT_FAILURE, error}
    }
}

function getTenantByIdAction(id) {
    return dispatch => {
        dispatch(request());
        tenantService.getTenantById(id)
            .then(
                tenant => dispatch(success(tenant)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: tenantConstants.GET_TENANT_BY_ID_REQUEST}
    }

    function success(tenant) {
        return {type: tenantConstants.GET_TENANT_BY_ID_SUCCESS, tenant}
    }

    function failure(error) {
        return {type: tenantConstants.GET_TENANT_BY_ID_FAILURE, error}
    }
}


function getTenantByUrlAction(url) {
    return dispatch => {
        dispatch(request());
        tenantService.getTenantByUrl(url).then(
                tenant => dispatch(success(tenant)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: tenantConstants.GET_TENANT_BY_URL_REQUEST}
    }

    function success(tenant) {
        return {type: tenantConstants.GET_TENANT_BY_URL_SUCCESS, tenant}
    }

    function failure(error) {
        return {type: tenantConstants.GET_TENANT_BY_URL_FAILURE, error}
    }
}

function uploadTenantLogoAction(id, image, icon, setDisable,from, msg) {
    return dispatch => {
        dispatch(request());
        tenantService.uploadTenantLogoService(id, image, icon)
            .then(doc => {
                if (doc['errorCode'] === 200) {
                    showSuccessToast(msg);
                    dispatch(success(doc));
                    showSuccessToast("Tenant updated successfully!");
                    setDisable(false)
                    history.push(from);
                    dispatch(tenantActions.getTenantByIdAction(id))
                } else {
                    responseDTOExceptionHandler(doc);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: tenantConstants.UPLOAD_TENANT_LOGO_REQUEST}
    }

    function success(doc) {
        return {type: tenantConstants.UPLOAD_TENANT_LOGO_SUCCESS, doc}
    }

    function failure(error) {
        return {type: tenantConstants.UPLOAD_TENANT_LOGO_FAILURE, error}
    }
}

{/***************************** tenant Actions Ends here **********************************/
}

{/****************** Reducers starts here. **************************************/
}

//get all tenants reducer
export function getAllPagedTenantReducer(tenant = {}, action) {
    switch (action.type) {
        case tenantConstants.GET_ALL_TENANT_REQUEST:
            return {
                ...tenant,
                loading: true,
                loggingIn: true,
            };
        case tenantConstants.GET_ALL_TENANT_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.tenants
            };
        case tenantConstants.GET_ALL_TENANT_FAILURE:
            return {};
        default:
            return tenant
    }
}

export function getTenantByIdReducer(tenant = {}, action) {
    switch (action.type) {
        case tenantConstants.GET_TENANT_BY_ID_REQUEST:
            return {};
        case tenantConstants.GET_TENANT_BY_ID_SUCCESS:
            return {
                items: action.tenant
            };
        case tenantConstants.GET_TENANT_BY_ID_FAILURE:
            return {};
        default:
            return tenant
    }
}

export function getTenantByNameReducer(tenant = {}, action) {
    switch (action.type) {
        case tenantConstants.GET_TENANT_BY_NAME_REQUEST:
            return {};
        case tenantConstants.GET_TENANT_BY_NAME_SUCCESS:
            return {
                items: action.tenant
            };
        case tenantConstants.GET_TENANT_BY_NAME_FAILURE:
            return {};
        default:
            return tenant
    }
}
export function getTenantByUrlReducer(tenant = {}, action) {
    switch (action.type) {
        case tenantConstants.GET_TENANT_BY_URL_REQUEST:
            return {};
        case tenantConstants.GET_TENANT_BY_URL_SUCCESS:
            return {
                items: action.tenant
            };
        case tenantConstants.GET_TENANT_BY_URL_FAILURE:
            return {};
        default:
            return tenant
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************tenant Services starts here  ****************************************/
}

//get all counties
export async function getAllTenant(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/tenants/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function createTenant(obj) {
    return axiosInstance.post(`/ajax/create/tenant`, obj)
        .then((response) => {
            return response.data
        })
}

export async function updateTenant(obj, tenantId) {
    return axiosInstance.put(`/ajax/update/tenant/${tenantId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function getTenantById(id) {
    return axiosInstance.get(`/ajax/tenants/${id}`).then(
        (response) => {
            return response.data;
        })
}
export async function getTenantByName(name) {
    return axiosInstance.get(`/ajax/tenants/name/${name}`).then(
        (response) => {
            return response.data;
        })
}

export async function getTenantByUrl(url) {
    return axiosInstance.get(`/ajax/base-url`, {
        headers: {
            'X-Tenant-Url': url
        }
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        console.error('Error fetching tenant by URL:', error);
        throw error;
    });
}

export async function uploadTenantLogoService(id, logo, icon) {
    let data = new FormData();
    data.append("file", logo);
    data.append("icon", icon);
    const response = await axiosInstance.post(`/ajax/upload/tenant-logo/${id}`, data);
    return exceptionHandler(response);
}

{/******************************tenant Services ends here  ****************************************/
}




